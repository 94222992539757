
let url
let pathrul
let host
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // url = "https://beta.qartoitus.com/api/homepage/";
  url = "http://192.168.1.109:9006/api/";
  host = "http://localhost:9006";
 	pathrul = "";
} else {
  url = "https://proto.qartoitus.com/api/";
  host = "https://proto.qartoitus.com";
	pathrul = "";
}
export let confs = {
  url: url,
  pathurl: pathrul,
  host: host
};