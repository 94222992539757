import React from 'react'
import {
  Row,
  Col
} from 'reactstrap'

import SneezingImage from '../../../assets/images/sneezingImage.png'

const Article = (props) => {
  return (
    <div className="article-block">
      <Row style={{ marginBottom: "0.5rem" }}>
        <Col xs={{ size: 2 }} md={{ size: 1 }} style={{ backgroundImage: `url(${SneezingImage})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}>
          
        </Col>
        
        <Col xs={{ size: 8 }} md={{ size: 9 }}>
          <Row>
            <Col xs={{ size: 12 }} style={{ color: props.dialogColor }}>
              { props.articleObj.title }
            </Col>
          </Row>
          <Row>
            <Col xs={{ size: 12 }}>
              <small>www.terve.fi</small>
            </Col>
          </Row>
        </Col>
        
        <Col xs={{ size: 2 }} style={{ color: props.dialogColor, textAlign: 'end' }}>
          <i className="material-icons">navigate_next</i>
        </Col>
      </Row>
    </div>
  )
}

export default Article