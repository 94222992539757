import React, { Component } from 'react'
import {
  Row,
  Col,
  Button
} from 'reactstrap'

import TimeBlock from './modules/timeBlock'
import Article from './modules/article'

import './endPage.css'
export default class EndPage extends Component {
  constructor() {
    super()

    this.state = {
      possibleDiagnoses: null,
      dialogColor: '#80DB2F',
      articles: []
    }
  }

  componentWillMount() {
    this.setState({ possibleDiagnoses: this.props.possibleDiagnoses, articles: this.props.articles })
  }

  navigateBack = () => {
    this.props.clearDiagnosis()
  }

  render() {
    const { dialogColor, possibleDiagnoses, articles } = this.state

    return (
      <div>

        {/* Navigate back */}

        <Row style={{ padding: "0.5rem 0 0 0.5rem" }} onClick={ () => this.navigateBack() }>
          <Col xs={{ size: 1 }} style={{padding: 0}}>
            <i className="material-icons" style={{ color: dialogColor, fontSize: "2.5rem" }}>navigate_before</i>
          </Col>
          <Col xs={{ size: 11 }} style={{ paddingTop: "0.5rem" }}>
            <p>Takaisin, tai jotain muuta tähän?</p>
          </Col>
        </Row>

        {/* Diagnosis section */}

        <Row>
          <Col xs={{ size: 12 }} style={{margin: '1rem 0 0'}}>
            <h4>Vertasin kertomaasi muihin saman kaltaisiin oireisiin ja diagnooseihin</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={{ size: 12 }}>
            { possibleDiagnoses.map(( diagnosisObj, index ) => {
              return <TimeBlock key={index} diagnosisObj={ diagnosisObj } dialogColor={ dialogColor } />
            }) }
          </Col>
        </Row>

        {/* Articles section */}

        <Row>
          <Col xs={{ size: 12 }} style={{margin: '1rem 0 0'}}>
            <h4>Kiinnostavia artikkeleita aiheesta</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={{ size: 12 }}>
            { articles.map((articleObj, index) => {
              return <Article key={ index } articleObj={ articleObj } dialogColor={ dialogColor } />
            }) }
          </Col>
        </Row>

        {/* Feedback section */}

        <Row className="mt-4">
          <Col xs={{ size: 12 }}>
            <button className="green-button mb-1">Tämä oli hyödyllinen</button>
            <button className="green-button mb-1">Tämä ei ollut hyödyllinen</button>
          </Col>
        </Row>
      </div>
    )
  }
}
