import React, { Component } from 'react'
import {
  Row,
  Col,
  Label
} from 'reactstrap'
import AsyncSelect from 'react-select/lib/Async'
import SymptomBlock from './modules/symptomBlock'

import './startPage.css'

export default class StartPage extends Component {
  constructor() {
    super()

    this.state = {
      symptoms: [],
      symptomInput: ''
    }
  }

  componentWillMount() {
    if( this.props.symptoms.length > 0 ) {
      this.setState({ symptoms: this.props.symptoms })
    }
  }

  selectSymptom = ( symptom ) => {
    const symptoms = [ ...this.state.symptoms ]
    symptoms.push( symptom )

    this.setState({ symptoms: symptoms })
  }

  selectSymptoms = () => {
    const { symptoms } = this.state

    this.props.setSymptoms(symptoms)
  }

  loadSymptoms = ( value ) => {
    return new Promise( async function(resolve) {
      if( value.length > 2 ) {
        const options = await this.props.loadSymptoms( value )

        resolve( options )
      } 
      
      resolve( [] )
    }.bind(this)) 
  }

  // onSymptomInputChange = ( value ) => {
  //   this.setState({ symptomInput: value })
  //   return value
  // }

  onSymptomSelect = ( value ) => {
    this.setState( prevState => ({
      symptoms: [ ...prevState.symptoms, value ],
      symptomInput: ''
    }))
  }

  removeSymptomSelection = ( index ) => {
    const symptoms = this.state.symptoms.filter(( item, i ) => i !== index)

    this.setState({ symptoms: symptoms })
  }

  filterOptions = (data) => {
    const selectedOptions = this.state.symptoms
    let showOption = true

    selectedOptions.map( obj => {
      if( showOption && obj.value === data.value ) {
        showOption = false
      }
    })

    return showOption
  }

  render() {
    const { symptoms, symptomInput } = this.state

    return (
      <div className="container-100">
        <Row>
          <Col sm={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="logo-with-bubble"></div>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 12 }} style={{ marginTop: '13vh', textAlign: 'center' }}>
            <h5 style={{ marginBottom: '0.5rem' }}>Miten voit?</h5>
            <Label for="symptom-input">Selvitä minuuteissa, mikä voi auttaa sinua voimaan paremmin!</Label>

            <AsyncSelect
              cacheOptions 
              loadOptions={ this.loadSymptoms }
              // onInputChange={ this.onSymptomInputChange }
              onChange={ this.onSymptomSelect }
              value={ symptomInput }
              placeholder="Hae oireita..."
              noOptionsMessage={ () => { return "Vaihda hakusanaa löytääksesi oireita" } }
              maxMenuHeight={ 200 }
              filterOption={ this.filterOptions }
            />
          </Col>
        </Row>
        { symptoms.length > 0 &&
          <div>
            <Row className="selected-symptoms-row">
              <Col xs={{ size: 12 }}>
                {symptoms && <label style={{width: "100%", marginBottom: "0"}}>Valitut oireet:</label>}

                  { symptoms.map(( symptom, index ) => {
                    return <SymptomBlock key={ index } index={ index } symptom={ symptom } removeSymptom={ this.removeSymptomSelection } />
                  }) }

              </Col>
            </Row> 
            <Row style={{ position: 'absolute', width: '100%', bottom: '1rem'}}>
              <Col xs={{ size: 12 }}>
                <button className="green-button" style={{ marginTop: "1rem" }} onClick={ () => this.selectSymptoms() }>Jatka</button>   
              </Col>
            </Row>
          </div>
        }
      </div>
    )
  }
}
