import React, { Component } from 'react'
import {
  Container
} from 'reactstrap'
import './App.css'

import Start from './Components/StartPage/startPage'
import SymptomDialog from './Components/SymptomDialog/symptomDialog'
import EndPage from './Components/EndPage/endPage'
import { confs } from './bootstrap/config'

const axios = require('axios')

class App extends Component {
  constructor() {
    super()

    this.state = {
      symptoms: [],
      hasNotSymptoms: [],
      symptomsSelected: false,
      symptomQuestion: null,
      possibleDiagnoses: null,
      diagnosesSelected: false,
      articles: []
    }

    this.setDiagnosis = this.setDiagnosis.bind(this)
    this.setSymptoms = this.setSymptoms.bind(this)
    this.addSymptom = this.addSymptom.bind(this)
  }

  loadSymptoms = ( search ) => {
    return new Promise( async function( resolve ) {
      const response = await axios({
        method: 'POST',
        url: confs.url + 'getSymptoms',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          symptom: search
        }
      })

      if( response.data.length > 0 ) {
        const symptoms = response.data
        // const filtered = symptoms.filter( val =>  val.text.toLowerCase().includes(search.toLowerCase()) )

        resolve( symptoms )
      }

      resolve( [] )
    })
  }

  async setSymptoms( symptoms ) {
    this.setState({ symptoms: symptoms }, async function() {
      await this.getDiagnoses()
      this.setState({ symptomsSelected: true })
    })
  }

  async addSymptom( symptom, hasSymptom = true ) {
    return new Promise( function( resolve ) {
      if( hasSymptom ) {
        const symptoms = [ ...this.state.symptoms ]
        symptoms.push( symptom )

        this.setState({ symptoms: symptoms }, () => resolve())
      } else {
        const hasNotSymptoms = [ ...this.state.hasNotSymptoms ]
        hasNotSymptoms.push( symptom )

        this.setState({ hasNotSymptoms: hasNotSymptoms }, () => resolve())
      }
      
    }.bind(this))
  }

  clearSymptoms = () => {
    this.setState({ symptomsSelected: false, diagnosesSelected: false, symptomQuestion: null })
  }

  getDiagnoses = () => {
    const { symptoms, hasNotSymptoms } = this.state

    return new Promise( async function( resolve ) {
      const response = await axios({
        method: 'POST',
        url: confs.url + 'getDiagnoses',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          symptoms: symptoms,
          hasNotSymptoms: hasNotSymptoms
        }
      })

      if( Object.keys(response.data).length > 0 ) {
        if( typeof response.data.symptomQuestion !== 'undefined' && response.data.symptomQuestion ) {
          await this.setSymptomQuestions( response.data.symptomQuestion )
        } 
        if( response.data.diagnoses && response.data.diagnoses.length > 0 ) {
          await this.setDiagnosis( response.data.diagnoses )
        }
        if( response.data.diagnosesReady || Object.keys(response.data.symptomQuestion).length === 0 ) {
          this.setState({ diagnosesSelected: true })
        }
      } else {
        
      }

      resolve()
    }.bind(this))
  }

  async setDiagnosis( diagnoses ) {
    return new Promise( async function( resolve ) {
      await this.setArticles()

      this.setState({ possibleDiagnoses: diagnoses }, () => {
        resolve()
      })
    }.bind(this))
    
    // this.setState({ possibleDiagnoses: [
    //   { diagnosis: 'Poskiontelontulehdus', possibility: '60%', description: 'Lorem ipsum dolor sit amet' },
    //   { diagnosis: 'Älytön flunssa', possibility: '25%', description: 'Lorem ipsum dolor sit amet' },
    //   { diagnosis: 'Kuubalainen hyppykuppa', possibility: '15%', description: 'Lorem ipsum dolor sit amet' }
    // ]})
  }

  clearDiagnosis = () => {
    this.setState({ symptomsSelected: false, diagnosesSelected: false, symptomQuestion: null })
  }

  setSymptomQuestions = ( symptoms ) => {
    return new Promise( function( resolve ) {
      this.setState({ symptomQuestion: symptoms }, () => resolve())
    }.bind(this))
  }

  setArticles = () => {
    return new Promise( function( resolve ) {
      this.setState({ articles: [
        { title: 'Poskiontelontulehdus - Uhka vai mahdollisuus?' },
        { title: 'Onko sinulla älytön flunssa? - Katso vinkit parempaan huomiseen.' },
        { title: 'Kuubalainen hyppykuppa on yleinen tuliainen etelän matkoilta.' }
      ] }, () => resolve())
    }.bind(this))
  }

  render() {
    const { symptoms, symptomsSelected, symptomQuestion, possibleDiagnoses, articles, diagnosesSelected } = this.state

    return (
      <Container className="App" style={{ position: 'relative' }}>
        { !symptomsSelected || !symptomQuestion ? (
          <Start symptoms={ symptoms } loadSymptoms={ this.loadSymptoms } setSymptoms={ this.setSymptoms } />
        ) : (
          !diagnosesSelected ? (
            <SymptomDialog symptoms={ symptoms } symptomQuestion={ symptomQuestion } clearSymptoms={ this.clearSymptoms } addSymptom={ this.addSymptom } getNextSymptomQuestion={ this.getDiagnoses } />
          ) : (
            <EndPage possibleDiagnoses={ possibleDiagnoses } articles={ articles } clearDiagnosis={ this.clearDiagnosis } />
          )
        ) }    
        
      </Container>
    );
  }
}

export default App
