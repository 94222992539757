import React, { Component } from 'react'
import {
  Row,
  Col
} from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars'

import './symptomDialog.css'

export default class SymptomDialog extends Component {
  constructor() {
    super()

    this.state = {
      symptoms: [],
      symptomQuestion: null,
      dialogHistory: [],
      symptomIndex: 0,
      currentAnswers: [],
      dialogColor: '#80DB2F'
    }
  }

  componentWillMount() {
    // haetaan container komponentissa ennen tämän mounttausta annettuun oireeseen liittyvät lisäkysymykset, ja sitten:
    this.setState({ symptoms: this.props.symptoms, symptomQuestion: this.props.symptomQuestion }, () => this.startDialog())
  }

  componentDidUpdate( ) {
    if( Object.keys(this.props.symptomQuestion).length > 0 && this.props.symptomQuestion !== this.state.symptomQuestion ) {
      this.setState({ symptomQuestion: this.props.symptomQuestion }, () => this.askQuestion())
    }
  }

  async startDialog() {
    const dialogStart = { type: 'question', text: 'Valitsit seuraavat oireet:' }
    await this.conversate( dialogStart )

    await this.startSymptoms()

    const plaa = { type: 'question', text: 'En pystynyt vielä kohdentamaan tarpeeksi tarkkaa diagnoosia antamiesi oireiden perusteella. Tarkennetaan hieman kertomaasi:' }
    await this.conversate( plaa )

    const firstQuestion = { type: 'question', text: 'Onko sinulla myös joitain seuraavista oireista?' }
    await this.conversate( firstQuestion )

    this.askQuestion()

  }

  async startSymptoms() {
    const symptoms = this.state.symptoms
    
    return new Promise( function( resolve ) {
      symptoms.map(( symptom ) => {
        let symptomDialog = { text: symptom.label, type: 'answer' }
        this.conversate( symptomDialog )
      })
      resolve()
    }.bind(this))
  }

  async askQuestion() {
    const { symptomQuestion } = this.state
    const currentAnswers = [ 'Kyllä', 'Ei' ]

    return new Promise( async function( resolve ) {
      const question = { type: 'question', text: symptomQuestion.label }
      await this.conversate( question )

      this.setState({ currentAnswers: currentAnswers })

      resolve()
    }.bind(this))
  }  

  /**
   * Pushes the given conversation object to the dialog history array
   * 
   * params:
   * conversationObj.type String type of either question/feedback or answer (but really anything other than question/feedback is considered as answer)
   * conversationObj.text String yeah it's the text of whatever we're pushing to the conversation
   * conversationObj.isTitle Boolean true if it's the title text of the dialog or the current question group  
   */
  conversate = ( conversationObj ) => {
    const { text, type } = conversationObj

    return new Promise(function( resolve ) {
      if( type === 'question' ) {
        const textLength = text.length
        let writeTime = textLength * 10 > 4000 ? 4000 : textLength * 10
        writeTime = writeTime < 1500 ? 1500 : writeTime

        const dialogHistory = [ ...this.state.dialogHistory ]
        console.log("NYT VITTU", dialogHistory)
        dialogHistory.push({ type: type, text: text })

        this.setState({
          aiWriting: true
        }, () => {
          setTimeout(() => {
            this.setState( prevState => ({
              dialogHistory: dialogHistory,
              aiWriting: false
            }), () => {
              console.log("doned",this.state.dialogHistory)
              this.handleScrollUpdate()
              resolve()
            })
          }, writeTime)
        })
      } else {
        // it's an answer. also clear current answers and freetext answer so nothing funny happens
        this.setState( prevState => ({
          dialogHistory: [
            ...prevState.dialogHistory,
            { type: 'answer', text: text }
          ],
          currentAnswers: []
        }), async function() {
          this.handleScrollUpdate()
          resolve()
        })
      }
    }.bind( this ))
  }

  async giveAnswer( e ) {
    const answer = e.target.getAttribute('data-text')
    
    await this.conversate({ type: 'answer', text: answer })

    const askNextQuestion = await this.getNextQuestion()

    if(askNextQuestion) {
      this.askQuestion()
    }
  }

  async giveSymptomAnswer( e ) {
    const { symptomQuestion } = this.state
    e.persist()

    const answer = e.target.getAttribute('data-text')

    if( answer === 'Kyllä' ) {
      await this.props.addSymptom( symptomQuestion, true )
    } else {
      await this.props.addSymptom( symptomQuestion, false )
    }

    this.giveAnswer( e )
  }

  getNextQuestion = () => {
    return new Promise(async function(resolve) {
      const nextSymptomQuestion = await this.props.getNextSymptomQuestion()

      if(nextSymptomQuestion) {
        this.setState({ symptomQuestion: nextSymptomQuestion })
      }
    }.bind(this))
  }

  navigateBack = () => {
    this.props.clearSymptoms()
  }

  handleScrollUpdate = () => {
    this.scrollEnd.parentElement.scroll({top: this.scrollEnd.offsetTop, left: 0, behavior: 'smooth'})
  }

  render() {
    const { dialogHistory, currentAnswers, dialogColor } = this.state

    return (
      <div>
        <Row style={{ padding: "0.5rem 0 0 0.5rem" }} onClick={() => this.navigateBack()}>
          <Col xs={{ size: 1 }} style={{ padding: 0 }}>
            <i className="material-icons" style={{color: dialogColor, fontSize: "2.5rem"}}>navigate_before</i>
          </Col>
          <Col xs={{ size: 11 }} style={{ paddingTop: "0.5rem" }}>
            <p>Takaisin, tai jotain muuta tähän?</p>
          </Col>
        </Row>
        <Row>
        <Scrollbars
          renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
          renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
          style={{width: "100%", height: "75vh", marginBottom: "1em"}}
          className="scrollbars-container"
          ref={(scrollBars) => this.scrollBars = scrollBars}
        >
          <Col sm={{ size: 12 }}>
            { dialogHistory.map(( dialogObj, index ) => {

              switch( dialogObj.type ) {
                case 'question':
                  return (
                        <Row key={ index } className="single-dialog-container">
                          {/* <div className="picture-circle question-picture" style={ this.state.sentSurveyData.name.indexOf('Ossi Aura') !== -1 ? style : {} } />  */}
                          <div className={"dialog-line dialog-question"}>
                            <p>{ dialogObj.text }</p>
                            {/* <TextEditor content={dialog.text} readOnly={true} onTextChange={() => {return}} additionalClass="dialog-freetext-question" /> */}
                          </div>
                        </Row>
                  )
                case 'answer':
                  return (
                        <Row key={ index } className="single-dialog-container"> 
                          <div className="dialog-line dialog-answer">
                            <p>{ dialogObj.text }</p>
                          </div>
                          {/* <div className="picture-circle answer-picture" /> */}
                        </Row>
                  )
              }
            })}
          </Col>
          <div ref={(el) => {this.scrollEnd = el;}}></div>
        </Scrollbars>
        </Row>
        <Row className="dialog-answers-container">
          { this.state.aiWriting && <p>Kirjoittaa...</p> }

          { currentAnswers.length > 0 && currentAnswers.map(( answer, index ) => {
            return (
              <button onClick={( e ) => this.giveSymptomAnswer( e )} style={{ backgroundColor: dialogColor, marginBottom: '0.3rem' }} key={ index } data-index={ index } data-text={ answer } className="green-button">
                { answer }
              </button>
            )
          })}
            
        </Row>
      </div>
    )
  }
}
