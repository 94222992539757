import React from 'react'

import './modules.css'

const SymptomBlock = ( props ) => {
  return (
    <div className="symptom-block" onClick={ () => props.removeSymptom( props.index ) }>
      <b>{ props.symptom.label }</b>
    </div>
  )
}

export default SymptomBlock