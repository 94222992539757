import React from 'react'
import {
  Row,
  Col
} from 'reactstrap'

import './modules.css'

const TimeBlock = ( props ) => {
  return (
    <div className="time-block">
      <Row style={{ color: props.dialogColor }}>
        <Col xs={{ size: 10 }}>
          { props.diagnosisObj.text }
        </Col>
        <Col xs={{ size: 2 }} style={{ textAlign: 'end' }}>
          { props.diagnosisObj.possibility }%
        </Col>
      </Row>
      {/* <Row>
        <Col xs={{ size: 12 }}>
          <small>{ props.diagnosisObj.description }</small>
          <small>Lorem ipsum dolor sit amet</small>
        </Col>
      </Row> */}
      <Row style={{ color: props.dialogColor, marginTop: "1rem" }}>
        <Col xs={{ size: 10 }}>
          Varaa aika
        </Col>
        <Col xs={{ size: 2 }} style={{ textAlign: 'end' }}>
          <i className="material-icons">navigate_next</i>
        </Col>
      </Row>
    </div>
  )
}

export default TimeBlock